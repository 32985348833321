@import url('https://fonts.googleapis.com/css?family=Gothic+A1|Kaushan+Script|Libre+Baskerville|Lobster');
html {
  scroll-behavior: smooth;
}
.body{
	font-family: 'Gothic A1', sans-serif;
	font-size:16px;
	}
	p{
	color:#6c6c6f;
	font-size:1em;
	}
	h1,h2,h3,h4,h5,h6{color:#323233;text-transform:uppercase;}
.navbar-brand  span{
	color: #fed136;
	font-size:25px;font-weight:700;letter-spacing:0.1em;
    font-family: 'Kaushan Script','Helvetica Neue',Helvetica,Arial,cursive;
}
.cursor_pointer{
  cursor: pointer;
}
.bg_white {
  background-color: #fff;
  border-radius: 5px;
}
.navbar-brand {
	color: #fff;
	font-size:25px;
    font-family: 'Kaushan Script','Helvetica Neue',Helvetica,Arial,cursive;
	font-weight:700;
	letter-spacing:0.1em;
}
.vehicle_header {
  color: grey;
  font-family: 'Kaushan Script','Helvetica Neue',Helvetica,Arial,cursive;
}
.bgAll {
  background-image: url(https://png.pngtree.com/thumb_back/fh260/background/20200731/pngtree-blue-carbon-background-with-sport-style-and-golden-light-image_371487.jpg);
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
.vehicleMain {
  font-size: 32px;
  font-family: 'Kaushan Script','Helvetica Neue',Helvetica,Arial,cursive;
  color: #fc983c;
  text-transform: capitalize;
}
.flag {
  width: 25px;
  border-radius: 50%;
  height: 25px;
  margin-right: 10px;
  margin-top: -5px;
}
.font_fam1 {
  font-family: 'Kaushan Script','Helvetica Neue',Helvetica,Arial,cursive;
}
.font_fam2 {
  font-family: "Poppins", sans-serif;
}
.borderBottom {
  border-bottom: 1px solid black;
}
.cursor_pointer {
  cursor: pointer;
}
.vehicle_value {
  color: #000;
  font-size: 26px;
  font-family: 'Kaushan Script','Helvetica Neue',Helvetica,Arial,cursive;
}

.navbar-nav .nav-item .nav-link{
	padding: 1.1em 1em!important;
	font-size: 120%;
    font-weight: 500;
    letter-spacing: 1px;
    color: #fff;
   font-family: 'Gothic A1', sans-serif;
}
.navbar-nav .nav-item .nav-link:hover{color:#fed136;}
.navbar-expand-md .navbar-nav .dropdown-menu{
	border-top:3px solid #fed136;
}
.dropdown-item:hover{background-color:#fed136;color:#fff;}
nav{-webkit-transition: padding-top .3s,padding-bottom .3s;
    -moz-transition: padding-top .3s,padding-bottom .3s;
    transition: padding-top .3s,padding-bottom .3s;
    border: none;
	}
	
 .shrink {
    padding-top: 0;
    padding-bottom: 0;
    background-color: #212529;
}
.banner{
	background-image:url('./image/bg_1.jpg');
  height: 100vh;
	text-align: center;
    color: #fff;
   
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
.bg-form {
  background-image:url('./image/slide-1.jpg');
	text-align: center;
    color: #fff;
   
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
.bg-form-personal {
  background-image:url('./image/slide-1.jpg');
	text-align: center;
    color: #fff;
   
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
}
.w-100 {
  width: 100%;
}
.form_wrap {
  background-color: #ffffff38;
  border-radius: 50px;
  box-shadow: 0px 10px 31px -21px rgb(0 0 0 / 33%);
  width: 50% !important;
  color: aliceblue;
  border-top-left-radius: 50px;
  overflow: hidden;
}
.form_wrap_personal {
  background-color: #ffffff38;
  border-radius: 50px;
  box-shadow: 0px 10px 31px -21px rgb(0 0 0 / 33%);
  width: 80% !important;
  color: aliceblue;
  border-top-left-radius: 50px;
  overflow: hidden;
}
.bg-form-billing {
  background-image:url('https://i.pinimg.com/originals/85/6f/31/856f31d9f475501c7552c97dbe727319.jpg');
	text-align: center;
    color: #fff;
   
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
}
.contact .info-wrap {
  box-shadow: 0px 2px 15px rgb(0 0 0 / 10%);
  padding: 30px;
}
.contact .info:hover i {
  background: #ffb03b;
  color: #fff;
}
.contact .info i {
  font-size: 20px;
  color: #ffb03b;
  float: left;
  width: 44px;
  height: 44px;
  background: #fff6e8;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}
.contact .info {
  background: #fff;
}
.contact .info h4 {
  padding: 0 0 0 60px;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #433f39;
  font-family: "Poppins", sans-serif;
}
.contact .info p {
    padding: 0 0 0 60px;
    margin-bottom: 0;
    font-size: 14px;
    color: #7a7368;
}
.activemenu {
  background-color: #fc983c;
}
.banner-text{
	padding:200px 0 150px 0;
}
.banner-heading{
	font-family: 'Lobster', cursive;
	font-size: 75px;
    font-weight: 700;
    line-height: 100px;
    margin-bottom: 30px;
	color:#fff;
}
.banner-sub-heading{
	font-family: 'Libre Baskerville', serif;
	font-size: 30px;
    font-weight: 300;
    line-height: 30px;
    margin-bottom: 50px;
	color:#fff;
}

.btn-banner{
	padding:5px 20px;
	border-radius:10px;
	font-weight:700;
	line-height:1.5;
	text-align:center;
	color:#fff;
	text-transform:uppercase;
  animation: 1s ease-out 0s 1 slideInFromLeft;
}
.text-intro{
	width:90%;
	margin:auto;
	text-align:center;
	padding-top:30px;
}
.form-control {
  border-radius: 1.25rem;
}

.font18 {
  font-size: 18px;
}
.fontwt400 {
  font-weight: 400;
}
.fontwt600 {
  font-weight: 600;
}
.fontwt900 {
  font-weight: 900;
}
.font16 {
  font-size: 16px;
}
.confirm {
  background: #424298;
    color: white;
    padding: 10px;
    border-top-right-radius: 50px;
    border-top-left-radius: 50px;
}
.border {
  border: 1px solid black;
}

.w20 {
  width: 10%;
}

.green {
  color: green;
}


/* mobile view */
@media (max-width:500px)
{
  .form_wrap {
    background-color: #ffffff38;
    border-radius: 50px;
    box-shadow: 0px 10px 31px -21px rgb(0 0 0 / 33%);
    width: 100% !important;
    color: aliceblue;
    border-top-left-radius: 50px;
    overflow: hidden;
  }
	.navbar-nav{
		background-color:#000;
		border-top:3px solid #fed136;
		color:#fff;
		z-index:1;
		margin-top:5px;
		}
	.navbar-nav .nav-item .nav-link{
	padding: 0.7em 1em!important;
	font-size: 100%;
    font-weight: 500;
    }
	.banner-text{
	padding:150px 0 150px 0;
}
.banner-heading{
	font-size: 30px;
    line-height: 30px;
    margin-bottom: 20px;
}
.banner-sub-heading{
	font-size: 10px;
    font-weight: 200;
    line-height: 10px;
    margin-bottom: 40px;
}
.bg-form-personal {
  background-image:url('./image/slide-1.jpg');
	text-align: center;
    color: #fff;
   
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100%;
}
.w20 {
  width: 50%;
}
}

@media (max-width:768px){
	.banner-text{
	padding:150px 0 150px 0;
}
	.banner-sub-heading{
	font-size: 23px;
    font-weight: 200;
    line-height: 23px;
    margin-bottom: 40px;
}
}

@keyframes slideInFromLeft {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
